import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Box, Flex } from "@chakra-ui/react";
import { useLocation } from "react-router";

const Layout = ({ children }) => {
  const location = useLocation();
  return (
    <Flex flexDir="column" minH="100vh" w="full" color="#000" bg="#F4F0E8">
      <Header />
      <Flex
        justifyContent="center"
        align="center"
        w="full"
        px={
          location.pathname === "/"
            ? { base: "0", md: "0", lg: "unset" }
            : { base: "20px", md: "20px", lg: "unset" }
        }
      >
        <Box
          pb={{ base: "50px", lg: "100px" }}
          w={
            location.pathname === "/services" || location.pathname === "/"
              ? { base: "full", md: "full", lg: "1024px", xl: "full" }
              : { base: "full", md: "full", lg: "1024px", xl: "1200px" }
          }
        >
          {children}
        </Box>
      </Flex>
      <Box mt="auto">
        <Footer />
      </Box>
    </Flex>
  );
};

export default Layout;
