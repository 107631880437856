export const headers = [
  {
    id: 1,
    name: "Home",
    path: "/",
  },
  {
    id: 2,
    name: "About",
    path: "/about-us",
  },
  {
    id: 3,
    name: "Services",
    path: "/services",
  },
  {
    id: 4,
    name: "Our Process",
    path: "/our-process",
  },
];

export const services = [
  {
    id: 1,
    icon: "/assets/icon1.svg",
    link: "/services/#hour",
    title: "24-Hour Care",
    text: "Experience the transformative difference of round-the-clock care, where our caregivers are there for you every hour, every day. From housekeeping to cleaning to meal prep, we have got you covered.",
  },
  {
    id: 2,
    icon: "/assets/icon.svg",
    link: "/services/#care",
    title: "Personal Care",
    text: "Personal care makes a transformative difference in the quality of life of the patients that need it. Our personal care service helps patients deal with issues like mobility, feeding and personal hygiene with their dignity intact.",
  },
  {
    id: 3,
    icon: "/assets/icon4.svg",
    link: "/services/#special",
    title: "Specialty Care",
    text: "Our specialty care service is designed to make the life of seniors suffering from chronic, often terminal diseases, meaningful and as pain-free as medically advised. Our caregivers are trained to care for your loved ones with Alzheimer's, Parkinson's and dementia.",
  },
  {
    id: 4,
    icon: "/assets/icon3.svg",
    link: "/services/#training",
    title: "Prefered Caregiver Training",
    text: "We can teach you how to care for your loved ones in their time of need. We will teach you practical and useful techniques that will help you provide them the care they need.",
  },
];

export const footerIcons = [
  "/assets/linkedin.svg",
  "/assets/facebook.svg",
  "/assets/instagram.svg",
];

export const reviews = [
  {
    id: 0,
    img: "/assets/review.jpg",
    desc: "I was worried and feeling guilty about not being able to care for my Dad because of work. Amos, the Glory Home caregiver assigned to my Dad is now a part of my family. I just love seeing him and Amos laugh and gossip and laugh together like little boys. I've never been happier with a service.",
    name: "ESTHER, Kendall County",
  },
  {
    id: 1,
    img: "/assets/review-two.jpg",
    desc: "I am a retired truck driver with diabetes. Moving around can be a chore some days and that's where Juno comes in. She gives me back and shoulder massages to ease the pain, cooks me meals and gives me my insulin shots when I need them. I don't have to worry about what to eat. I even have time for short supervised walks with my dog.",
    name: "MAGARET, Cook County",
  },
  {
    id: 2,
    img: "/assets/review-three.jpg",
    desc: "I am still adjusting to life on a wheelchair but James, my Glory Home caregiver, is making the adjustment both fun and easy. He has made my entire home suitable for wheelchair movement. He put detachable wooden ramps everywhere that has an incline. I can even cook my own meals with supervision.",
    name: "SASHA, Dupage County",
  },
  {
    id: 3,
    img: "/assets/review-four.jpg",
    desc: "It's hard to find someone that can keep my kitchen as clean as I like it but Susan has proven herself equal to the task. I'm recovering from a hip replacement surgery so mobility is an issue. Susan does everything well. She helps me shower, helps me dress and keeps me company. But most of all, I'm impressed at how tidy she keeps my kitchen. Susan has been a lifesaver.",
    name: "JOAN, Lake County",
  },
];
