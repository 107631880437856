import React, { useEffect, useState } from "react";
import {
  Flex,
  Image,
  Text,
  Button,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import { headers } from "../components/common/constants";
import { NavLink, useNavigate } from "react-router-dom";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { motion } from "framer-motion";

const Header = () => {
  const activeStyle = {
    borderBottom: "5px solid #8F512E",
    padding: "0 5px",
  };

  const mobileStyle = {
    color: "#CC6E3B",
  };

  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest(".box") === null) {
        onClose();
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      onClose();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [onClose]);

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 20);
    });
  }, []);

  return (
    <Flex
      bg="#F4F0E8"
      id="header"
      zIndex={666}
      w="full"
      pos="sticky"
      boxShadow={scroll && "0px 4px 60px 0px rgba(221, 161, 94, 0.30)"}
      transition=".3s ease-in-out"
      top="0"
      justifyContent="center"
      pb="0"
      h={{ base: "80px", lg: "80px" }}
      align="flex-end"
    >
      <Box w={{ base: "full", md: "full", lg: "1024px", xl: "1200px" }}>
        <Flex
          fontFamily="Archivo"
          w="100%"
          px={{ base: "20px", lg: "unset" }}
          borderBottom="1px solid #DDA15E"
          pb={{ base: "22px", lg: "unset" }}
          align="flex-start"
          justifyContent="space-between"
        >
          <Image
            onClick={() => navigate("/")}
            mt="5px"
            cursor="pointer"
            src="/assets/logo.svg"
            display={{ base: "none", lg: "flex" }}
          />
          <Image
            onClick={() => navigate("/")}
            mt="5px"
            w="150px"
            src="/assets/mobileLogo.svg"
            display={{ base: "flex", lg: "none" }}
          />
          <Flex gap="20px" align="center">
            <Button
              display={{ base: "flex", lg: "none" }}
              onClick={() => navigate("/contact-us")}
              w="112px"
              h="36px"
              size="sm"
              fontSize="14px"
            >
              Contact Us
            </Button>
            <Box pos="relative" className="box">
              <Box display={{ base: isOpen ? "flex" : "none", lg: "none" }}>
                <AiOutlineClose onClick={onClose} color="#DDA15E" size="24px" />
              </Box>
              <Box display={{ base: isOpen ? "none" : "flex", lg: "none" }}>
                <AiOutlineMenu onClick={onOpen} color="#DDA15E" size="24px" />
              </Box>
              {isOpen && (
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ y: [6, 0], opacity: 1 }}
                >
                  <Box
                    w="201px"
                    bg="#646464"
                    p="20px"
                    pos="absolute"
                    right="0"
                    top="47px"
                  >
                    {headers.map((dat, i) => (
                      <NavLink
                        key={i}
                        onClick={onClose}
                        to={dat?.path}
                        style={({ isActive }) =>
                          isActive
                            ? { ...mobileStyle }
                            : {
                                ...mobileStyle,
                                borderBottom: "unset",
                                color: "#fff",
                              }
                        }
                      >
                        <Text lineHeight="23px" pb="9px" fontSize="15px">
                          {dat?.name}
                        </Text>
                      </NavLink>
                    ))}
                  </Box>
                </motion.div>
              )}
            </Box>
          </Flex>
          <Flex
            display={{ base: "none", lg: "flex" }}
            gap="50px"
            align="flex-start"
          >
            {headers.map((dat, i) => (
              <NavLink
                key={i}
                to={dat?.path}
                style={({ isActive }) =>
                  isActive
                    ? { ...activeStyle }
                    : {
                        ...activeStyle,
                        borderBottom: "unset",
                      }
                }
              >
                <Text lineHeight="25px" pb="22px" fontSize="18px">
                  {dat?.name}
                </Text>
              </NavLink>
            ))}
            <Button
              onClick={() => navigate("/contact-us")}
              w="112px"
              mt="-5px"
              fontSize="14px"
            >
              Contact Us
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Header;
