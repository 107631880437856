import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";
import { footerIcons } from "../components/common/constants";
import { useNavigate } from "react-router";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Flex
      bg="#323232"
      w="full"
      flexDir="column"
      justifyContent="center"
      zIndex={66}
      pb="0"
      py={{ base: "30px", md: "unset" }}
      h={{ base: "unset", md: "90px" }}
      align="center"
    >
      <Flex
        display={{ base: "none", md: "flex" }}
        w={{ base: "full", md: "full", lg: "1024px", xl: "1200px" }}
        flexWrap={{ base: "wrap", md: "nowrap" }}
        align="center"
        justifyContent="space-between"
      >
        <Image
          cursor="pointer"
          onClick={() => navigate("/")}
          src="/assets/footerLogo.svg"
        />

        <Text fontFamily="Archivo" fontSize="14px" color="#fff">
          2023 Glory Home Healthcare, Inc.
        </Text>

        <Flex gap="30px" align="center">
          {footerIcons.map((data, i) => (
            <Image w="30px" h="30px" src={data} key={i} />
          ))}
        </Flex>
      </Flex>

      <Flex
        display={{ base: "flex", md: "none" }}
        w={{ base: "full", md: "full", lg: "1024px", xl: "1200px" }}
        gap="30px"
        px="20px"
        align="center"
        justifyContent="space-between"
      >
        <Image onClick={() => navigate("/")} src="/assets/footerLogo.svg" w="126px" />

        <Flex
          justifyContent="space-between"
          align="flex-end"
          gap="7px"
          flexDir="column-reverse"
        >
          <Text fontFamily="Archivo" fontSize="12px" color="#fff">
            2023 Glory Home Healthcare, Inc.
          </Text>

          <Flex gap="15px" align="center">
            {footerIcons.map((data, i) => (
              <Image w="15px" h="15px" src={data} key={i} />
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
