import React, { useEffect } from "react";
import Pages from "./routes";

const App = () => {
  const imagesToPreload = [
    "/assets/caregiver.jpg",
    "/assets/couple.jpg",
    "/assets/facebook.svg",
    "/assets/footerLogo.svg",
    "/assets/help.jpg",
    "/assets/hero.jpg",
    "/assets/icon.svg",
    "/assets/icon1.svg",
    "/assets/icon2.svg",
    "/assets/icon3.svg",
    "/assets/icon4.svg",
    "/assets/instagram.svg",
    "/assets/left-arrow.svg",
    "/assets/linkedin.svg",
    "/assets/logo.svg",
    "/assets/map.png",
    "/assets/mobileLogo.svg",
    "/assets/mum.jpg",
    "/assets/nurse.jpg",
    "/assets/review-five.jpg",
    "/assets/review-four.jpg",
    "/assets/review-three.jpg",
    "/assets/review-two.jpg",
    "/assets/review.jpg",
    "/assets/right-arrow.svg",
    "/assets/smallleft.svg",
    "/assets/smallright.svg",
    "/assets/teacher.jpg",
  ];

  const preloadImages = () => {
    imagesToPreload.forEach((imagePath) => {
      const link = document.createElement("link");
      link.rel = "preload";
      link.href = imagePath;
      link.as = "image";
      document.head.appendChild(link);
    });
  };
  useEffect(() => {
    preloadImages();
  }, []);

  return <Pages />;
};

export default App;
