import { lazy } from "react";
import WithSuspense from "../components/Loaders/WithSuspense";
import { PATHS } from "./constants";
import { Navigate } from "react-router-dom";

const { LANDING, SERVICES, CONTACT, PROCESS, ABOUT } = PATHS;

const Landing = WithSuspense(lazy(() => import("../pages/Landing")));
const Process = WithSuspense(lazy(() => import("../pages/Process")));
const About = WithSuspense(lazy(() => import("../pages/About")));
const Contact = WithSuspense(lazy(() => import("../pages/Contact")));
const Services = WithSuspense(lazy(() => import("../pages/Services")));

export const ROUTES = [
  { path: LANDING, element: <Landing /> },
  { path: PROCESS, element: <Process /> },
  { path: ABOUT, element: <About /> },
  { path: SERVICES, element: <Services /> },
  { path: CONTACT, element: <Contact /> },
  { path: "*", element: <Navigate to="/" replace /> },
];
